(function(global) {
    var counter = 0, timeout;
    var preloader = document.querySelector('.preloader');
    var progressBar = document.querySelector('.preloader-progress-bar');
    var body = document.querySelector('body');

    // if preloader not present => abort
    if (!preloader) return;

    // disables scrollbar
    body.style.overflow = 'hidden';

    timeout = setTimeout(startCounter, 20);


    function startCounter() {
        setTimeout(() => endCounter(), 400);
    }

    function endCounter() {
        clearTimeout(timeout);
        if (progressBar) progressBar.style.width = '100%';
        setTimeout(function() {
            // animate preloader hiding
            removePreloader();
            // retore scrollbar
            body.style.overflow = '';
        }, 300);
    }

    function removePreloader() {
        preloader.addEventListener('transitionend', function() {
            preloader.className = 'preloader-hidden';
        });
        preloader.className += ' preloader-hidden-add preloader-hidden-add-active';
    };

})(window);
